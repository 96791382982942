import React, { useState, useContext } from "react";
import { useHistory, Link } from "react-router-dom";
import userContext from "../userContext";
import {
  Navbar,
  Container,
  Row,
  Col,
  Form,
  Button,
  Alert,
} from "react-bootstrap";
import {
  ImFacebook,
  ImTwitter,
  ImLinkedin,
  ImYoutube,
  ImInstagram,
} from "react-icons/im";
import axios from "axios";
import qs from "qs";
import Logo from "../images/logo.png";

const appname = process.env.REACT_APP_NAME;

const Login = () => {
  let history = useHistory();
  const { user, setUser } = useContext(userContext);
  const [isAuth, setIsAuth] = useState(null);
  const [isDisabled, setIsDisabled] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = qs.stringify({
      email: e.target.inputEmail.value,
      password: e.target.inputPassword.value,
    });

    try {
      const res = await axios.post("/api/auth/login", data, {
        withCredentials: true,
      });
      setUser(res.data);

      history.push("/account");
    } catch (error) {
      setIsAuth(false);
      setIsDisabled(true);
    }
  };

  const handleChange = () => {
    setIsDisabled(false);
    setIsAuth(null);
  };

  return (
    <div className="background-image container-fluid">
      <Navbar>
        <Navbar.Brand>
          <img src={Logo} width="auto" height="80" alt="" />
          <span
            className="align-middle font-italic"
            style={{
              fontSize: "52px",
              color: "#e56958",
              marginLeft: "20px",
            }}
          >
            {appname}
          </span>
        </Navbar.Brand>
      </Navbar>

      <div
        style={{
          height: "80vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Container>
          <Row>
            <Form
              className="form-signin"
              onSubmit={handleSubmit}
              style={{ textAlign: "center", backgroundColor: "#002f5f0f" }}
            >
              <h2 style={{ marginBottom: "16px" }}>Inloggen</h2>
              <Form.Group>
                <Form.Control
                  type="email"
                  id="inputEmail"
                  className="form-control"
                  placeholder="E-mailadres"
                  required
                  value={user?.email}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group>
                <input
                  type="password"
                  id="inputPassword"
                  className="form-control"
                  placeholder="Wachtwoord"
                  required
                  value={user?.password}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group>
                <Button
                  variant="primary"
                  type="submit"
                  block
                  disabled={isDisabled}
                >
                  Inloggen
                </Button>
              </Form.Group>
              <Form.Group>
                <Form.Text>
                  <Link to="/forget" style={{ color: "#3796ba" }}>
                    Wachtwoord vergeten?
                  </Link>
                </Form.Text>
              </Form.Group>
              {isAuth === false && (
                <Alert variant="danger">
                  De combinatie van e-mailadres en wachtwoord is onjuist.
                </Alert>
              )}
            </Form>
          </Row>
        </Container>
        <footer className="footer" style={{ justifyContent: "center" }}>
          <Container>
            <Row className="justify-content-md-center">
              <Col className="text-center">
                <a
                  href="https://www.facebook.com/Oceanz3Dprinting/"
                  title="Facebook"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <ImFacebook
                    size={"24px"}
                    style={{
                      color: "#00AFD8",
                      marginLeft: "6px",
                      marginRight: "6px",
                    }}
                  />
                </a>
                <a
                  href="https://twitter.com/oceanz3dprint"
                  title="Twitter"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <ImTwitter
                    size={"24px"}
                    style={{
                      color: "#00AFD8",
                      marginLeft: "6px",
                      marginRight: "6px",
                    }}
                  />
                </a>
                <a
                  href="https://www.linkedin.com/company/oceanz-3d-printing/"
                  title="LinkedIn"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <ImLinkedin
                    size={"24px"}
                    style={{
                      color: "#00AFD8",
                      marginLeft: "6px",
                      marginRight: "6px",
                    }}
                  />
                </a>
                <a
                  href="https://www.youtube.com/channel/UCCuPG5U__ZjzS8GIPniqnBA"
                  title="Youtube"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <ImYoutube
                    size={"24px"}
                    style={{
                      color: "#00AFD8",
                      marginLeft: "6px",
                      marginRight: "6px",
                    }}
                  />
                </a>
                <a
                  href="https://www.instagram.com/oceanz3d/"
                  title="Instagram"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <ImInstagram
                    size={"24px"}
                    style={{
                      color: "#00AFD8",
                      marginLeft: "6px",
                      marginRight: "6px",
                    }}
                  />
                </a>
              </Col>
            </Row>
            <Row style={{ marginTop: "15px" }}>
              <Col className="text-center">
                <span style={{ color: "#d4d4d4" }}>
                  &copy; 2021 Oceanz B.V. |
                </span>{" "}
                <a
                  href="https://www.oceanz.eu/algemene-voorwaarden/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <span style={{ color: "#d4d4d4" }}>Algemene voorwaarden</span>
                </a>{" "}
                <span style={{ color: "#d4d4d4" }}>|</span>{" "}
                <a
                  href="https://www.oceanz.eu/privacyverklaring/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <span style={{ color: "#d4d4d4" }}>Privacy verklaring</span>
                </a>
              </Col>
            </Row>
          </Container>
        </footer>
      </div>
    </div>
  );
};

export default Login;
