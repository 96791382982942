import React, { useState } from "react";
import { Form, Button, Alert } from "react-bootstrap";
import { Formik } from "formik";
import * as yup from "yup";
import axios from "axios";
import useIsMounted from "../utils/ismounted";

const validationSchema = yup.object({
  rmuserEmail: yup
    .string()
    .email("Dit is geen e-mailadres")
    .required("Verplicht veld"),
});

const initialValues = {
  rmuserEmail: "",
};

const RemoveUserForm = () => {
  const isMounted = useIsMounted();
  const [succes, setSucces] = useState(false);
  const [error, setError] = useState(false);

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={async (values, actions) => {
        try {
          await axios
            .post("/api/auth/token", null, {
              withCredentials: true,
            })
            .then(() => {
              axios
                .delete(`/api/auth/remove/${values.rmuserEmail}`, {
                  withCredentials: true,
                })
                .then((result) => {
                  if (isMounted.current) {
                    actions.setSubmitting(false);
                    actions.resetForm();
                    setSucces(true);
                  }
                })
                .catch((err) => {
                  actions.setSubmitting(false);
                  actions.resetForm();
                  setError(true);
                });
            });
        } catch (error) {
          actions.setSubmitting(false);
          actions.resetForm();
          setError(true);
        }
      }}
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        touched,
        values,
        errors,
        isSubmitting,
      }) => (
        <div style={{ fontFamily: "calibri" }}>
          <Form onSubmit={handleSubmit}>
            <h4>Gebruikers verwijderen</h4>
            <Form.Group controlId="rmuserEmail">
              <Form.Control
                type="email"
                name="rmuserEmail"
                placeholder="E-mailadres"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.rmuserEmail || ""}
                isInvalid={touched.rmuserEmail && errors.rmuserEmail}
              />
              <Form.Control.Feedback type="invalid">
                {errors.rmuserEmail}
              </Form.Control.Feedback>
            </Form.Group>

            <Button
              variant="danger"
              type="submit"
              onClick={() => {
                setSucces(false);
                setError(false);
              }}
              disabled={isSubmitting}
            >
              {isSubmitting ? "Even geduld A.U.B..." : "Gebruiker verwijderen"}
            </Button>
          </Form>
          <br />
          <Alert
            show={succes}
            variant="success"
            onClose={() => setSucces(false)}
            dismissible
          >
            <p>Gebruiker verwijderd!</p>
          </Alert>
          <Alert
            show={error}
            variant="danger"
            onClose={() => setError(false)}
            dismissible
          >
            <p>Gebruiker kon niet worden verwijderd!</p>
          </Alert>
        </div>
      )}
    </Formik>
  );
};

export default RemoveUserForm;
