import React, { useState } from "react";
import { Route, BrowserRouter as Router } from "react-router-dom";
import userContext from "./userContext";
import Home from "./components/home";
import Account from "./components/account";
import Forget from "./components/forget";

import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";

const App = () => {
  const [user, setUser] = useState(null);

  return (
    <Router>
      <userContext.Provider value={{ user, setUser }}>
        <Route exact path="/" component={Home} />
        <Route path="/account" component={Account} />
        <Route path="/forget" component={Forget} />
      </userContext.Provider>
    </Router>
  );
};

export default App;
