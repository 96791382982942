import React, { useState } from "react";
import { Form, Button, Alert } from "react-bootstrap";
import { Formik } from "formik";
import * as yup from "yup";
import axios from "axios";
import useIsMounted from "../utils/ismounted";

const validationSchema = yup.object({
  oldPassword: yup.string().required("Verplicht veld"),
  newPassword: yup
    .string()
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&=`':;~"/\\|^+-])[A-Za-z\d@$!%*#?&=`';:~"/\\|^+-]{8,}$/,
      "Minimaal 8 karakters, een hoofdletter, een kleine letter, een nummer en een speciaal teken"
    )
    .required("Verplicht veld"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("newPassword"), null], "Wachtwoorden zijn niet hetzelfde")
    .required("Verplicht veld"),
});

const initialValues = {
  oldPassword: "",
  newPassword: "",
  confirmPassword: "",
};

const ChangePasswordForm = () => {
  const isMounted = useIsMounted();
  const [succes, setSucces] = useState(false);
  const [error, setError] = useState(false);

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={async (values, actions) => {
        try {
          await axios
            .post("/api/auth/token", null, {
              withCredentials: true,
            })
            .then(() => {
              axios
                .post(
                  "/api/auth/change",
                  {
                    password: values.oldPassword,
                    new_password: values.newPassword,
                  },
                  {
                    withCredentials: true,
                  }
                )
                .then((result) => {
                  if (isMounted.current) {
                    actions.setSubmitting(false);
                    actions.resetForm();
                    setSucces(true);
                  }
                })
                .catch((err) => {
                  actions.setSubmitting(false);
                  actions.resetForm();
                  setError(true);
                });
            });
        } catch (error) {
          actions.setSubmitting(false);
          actions.resetForm();
          setError(true);
        }
      }}
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        touched,
        values,
        errors,
        isSubmitting,
      }) => (
        <div>
          <Form onSubmit={handleSubmit}>
            <h4>Wachtwoord wijzigen</h4>
            <Form.Group controlId="oldPassword">
              <Form.Control
                name="oldPassword"
                placeholder="Huidige wachtwoord"
                className="form-control"
                type="password"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.oldPassword || ""}
                isInvalid={touched.oldPassword && errors.oldPassword}
              />
              <Form.Control.Feedback type="invalid">
                {errors.oldPassword}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="newPassword">
              <Form.Control
                name="newPassword"
                placeholder="Nieuw wachtwoord"
                className="form-control"
                type="password"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.newPassword || ""}
                isInvalid={touched.newPassword && errors.newPassword}
              />
              <Form.Control.Feedback type="invalid">
                {errors.newPassword}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="confirmPassword">
              <Form.Control
                name="confirmPassword"
                placeholder="Bevestig nieuwe wachtwoord"
                className="form-control"
                type="password"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.confirmPassword || ""}
                isInvalid={touched.confirmPassword && errors.confirmPassword}
              />
              <Form.Control.Feedback type="invalid">
                {errors.confirmPassword}
              </Form.Control.Feedback>
            </Form.Group>

            <Button
              variant="primary"
              type="submit"
              onClick={() => {
                setSucces(false);
                setError(false);
              }}
              disabled={isSubmitting}
            >
              {isSubmitting ? "Even geduld A.U.B..." : "Wachtwoord wijzigen"}
            </Button>
          </Form>
          <br />
          <Alert
            style={{ fontSize: "16px" }}
            show={succes}
            variant="success"
            onClose={() => setSucces(false)}
            dismissible
          >
            <p>Wachtwoord gewijzigd!</p>
          </Alert>
          <Alert
            style={{ fontSize: "16px" }}
            show={error}
            variant="danger"
            onClose={() => setError(false)}
            dismissible
          >
            <p>Wachtwoord kon niet worden gewijzigd!</p>
          </Alert>
        </div>
      )}
    </Formik>
  );
};

export default ChangePasswordForm;
