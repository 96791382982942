import React, { useContext } from "react";
import { Container, Row, Col } from "react-bootstrap";
import ChangePassword from "./changepassword";
import AddUser from "./adduser";
import RemoveUser from "./removeuser";
import AddToken from "./addtoken";
import RemoveToken from "./removetoken";

import userContext from "../userContext";

const AccountSettings = () => {
  const { user } = useContext(userContext);

  return (
    <div>
      <Container fluid>
        <Col md={4}>
          <Row>
            <Col style={{ paddingTop: "15px" }}>
              <h2>Instellingen</h2>
            </Col>
          </Row>
          <Row>
            <Col style={{ paddingTop: "15px" }}>
              <ChangePassword />
            </Col>
          </Row>
        </Col>
      </Container>
      {user.role === "admin" && (
        <Container fluid>
          <Col md={4}>
            <Row>
              <Col style={{ paddingTop: "15px" }}>
                <AddUser />
              </Col>
            </Row>
          </Col>
        </Container>
      )}
      <br />
      {user.role === "admin" && (
        <Container fluid>
          <Col md={4}>
            <Row>
              <Col style={{ paddingTop: "15px" }}>
                <RemoveUser />
              </Col>
            </Row>
          </Col>
        </Container>
      )}
      <br />
      {user.role === "admin" && (
        <Container fluid>
          <Col md={4}>
            <Row>
              <Col style={{ paddingTop: "15px" }}>
                <AddToken />
              </Col>
            </Row>
          </Col>
        </Container>
      )}
      {user.role === "admin" && (
        <Container fluid>
          <Col md={4}>
            <Row>
              <Col style={{ paddingTop: "15px" }}>
                <RemoveToken />
              </Col>
            </Row>
          </Col>
        </Container>
      )}
    </div>
  );
};

export default AccountSettings;
