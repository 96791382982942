import React, { useState } from "react";
import { Form, Button, Alert } from "react-bootstrap";
import { Formik } from "formik";
import * as yup from "yup";
import axios from "axios";
import useIsMounted from "../utils/ismounted";

const roles = [
  {
    key: "user",
    value: "Gebruiker",
  },
  {
    key: "admin",
    value: "Beheerder",
  },
];

const validationSchema = yup.object({
  userName: yup
    .string()
    .min(2, "Minimaal 2 karakters")
    .required("Verplicht veld"),
  userEmail: yup
    .string()
    .email("Dit is geen e-mailadres")
    .required("Verplicht veld"),
  userRole: yup
    .string()
    .oneOf(["user", "admin"], "Onjuiste rol")
    .required("Required"),
});

const initialValues = {
  userName: "",
  userEmail: "",
  userRole: "user",
};

const AddUserForm = () => {
  const isMounted = useIsMounted();
  const [show, setShow] = useState(false);
  const [error, setError] = useState(null);
  const [user, setUser] = useState();

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={async (values, actions) => {
        setUser({});

        try {
          await axios
            .post("/api/auth/token", null, {
              withCredentials: true,
            })
            .then(() => {
              axios
                .post(
                  "/api/auth/register",
                  {
                    email: values.userEmail,
                    role: values.userRole,
                    name: values.userName,
                  },
                  {
                    withCredentials: true,
                  }
                )
                .then((result) => {
                  console.log(result);
                  if (isMounted.current) {
                    let userRole = roles.find(
                      (r) => r.key === result.data.role
                    ).value;

                    setUser({
                      userEmail: result.data.email,
                      password: result.data.hash_password,
                      userRole: userRole,
                    });
                    actions.setSubmitting(false);
                    actions.resetForm();
                    setShow(true);
                  }
                })
                .catch((err) => {
                  actions.setSubmitting(false);
                  actions.resetForm();
                  setError(
                    `Account voor ${values.userEmail} is al eerder aangemaakt!`
                  );
                });
            });
        } catch (error) {
          actions.setSubmitting(false);
          actions.resetForm();
          setError(
            `Account voor ${values.userEmail} kan niet worden aangemaakt!`
          );
        }
      }}
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        touched,
        values,
        errors,
        isSubmitting,
      }) => (
        <div>
          <Form onSubmit={handleSubmit}>
            <h4>Gebruikers aanmaken</h4>
            <Form.Group controlId="userEmail">
              <Form.Control
                type="email"
                name="userEmail"
                placeholder="E-mailadres"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.userEmail || ""}
                isInvalid={touched.userEmail && errors.userEmail}
              />
              <Form.Control.Feedback type="invalid">
                {errors.userEmail}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="userName">
              <Form.Control
                type="text"
                name="userName"
                placeholder="Naam"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.userName || ""}
                isInvalid={touched.userName && errors.userName}
              />
              <Form.Control.Feedback type="invalid">
                {errors.userName}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="userRole">
              <Form.Control
                as="select"
                htmlSize={1}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.userRole || "user"}
              >
                <option value="user">
                  {roles.find((r) => r.key === "user").value}
                </option>
                <option value="admin">
                  {roles.find((r) => r.key === "admin").value}
                </option>
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                {errors.userRole}
              </Form.Control.Feedback>
            </Form.Group>

            <Button
              variant="success"
              type="submit"
              onClick={() => {
                setShow(false);
                setError(null);
              }}
              disabled={isSubmitting}
            >
              {isSubmitting ? "Even geduld A.U.B..." : "Gebruiker toevoegen"}
            </Button>
          </Form>
          <br />
          <Alert
            style={{ fontSize: "16px" }}
            show={show}
            variant="success"
            onClose={() => setShow(false)}
            dismissible
          >
            <p>
              Account: {user?.userEmail}
              <br />
              Wachtwoord: {user?.password}
              <br />
              Rol: {user?.userRole}
            </p>
          </Alert>
          <Alert
            style={{ fontSize: "16px" }}
            show={error !== null}
            variant="danger"
            onClose={() => setError(null)}
            dismissible
          >
            <p>{error}</p>
          </Alert>
        </div>
      )}
    </Formik>
  );
};

export default AddUserForm;
