import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import Rondje from "../images/rondje.png";
import QRCode from "react-qr-code";

const About = () => {
  const appname = process.env.REACT_APP_NAME;
  const version = process.env.REACT_APP_VERSION;
  const email = process.env.REACT_APP_EMAIL;

  const data = `MATMSG:TO:${email};SUB:Support aanvraag ${appname}, versie ${version};BODY:;;`;

  return (
    <div>
      <Container fluid>
        <Row>
          <Col style={{ paddingTop: "15px" }}>
            <h3>Info over</h3>
          </Col>
        </Row>
        <Row>
          <Col style={{ paddingTop: "15px" }}>
            <Image
              style={{
                float: "left",
                paddingRight: "10px",
                paddingTop: "5px",
                paddingLeft: "5px",
              }}
              src={Rondje}
              height="40"
              roundedCircle
            />
            <p>
              {appname}
              <br />
              Versie: {version}
            </p>
          </Col>
        </Row>
        <Row>
          <Col style={{ paddingTop: "10px" }}>
            <small>&copy; 2021 Oceanz B.V.</small>
          </Col>
        </Row>
        {/* <Row>
          <Col style={{ paddingTop: "50px" }}>
            <h3>Vorige versies</h3>
          </Col>
        </Row>
        <Row>
          <Col style={{ paddingTop: "15px" }}>
            <ul>
              <li>22.1.0.1 Eerste versie</li>
            </ul>
          </Col>
        </Row>
        <Row>
          <Col style={{ paddingTop: "50px" }}>
            <h3>Nieuw in deze versie</h3>
          </Col>
        </Row>
        <Row>
          <Col style={{ paddingTop: "15px" }}>
            <ul>
              <li></li>
            </ul>
          </Col>
        </Row> */}
        <Row>
          <Col style={{ paddingTop: "50px" }}>
            <h3>Support</h3>
          </Col>
        </Row>
        <Row>
          <Col style={{ paddingTop: "15px" }}>
            <p>Scan onderstaande QR code om een probleem aan te melden.</p>
            <QRCode size={128} value={data} />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default About;
