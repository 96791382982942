import React, { useState } from "react";
import { Form, Button, Alert } from "react-bootstrap";
import { Formik } from "formik";
import * as yup from "yup";
import axios from "axios";
import useIsMounted from "../utils/ismounted";

const roles = [
  {
    key: "user",
    value: "Gebruiker",
  },
  {
    key: "admin",
    value: "Administrator",
  },
];

const levels = [
  {
    key: "development",
    value: "Ontwikkeling",
  },
  {
    key: "production",
    value: "Productie",
  }
];

const validationSchema = yup.object({
  tokenApp: yup
    .string()
    .oneOf(["yourshipping"], "Onjuiste applicatie")
    .required("Required"),
  tokenEmail: yup
    .string()
    .email("Dit is geen e-mailadres")
    .required("Verplicht veld"),
  tokenSource: yup.string().required("Verplicht veld"),
  tokenRole: yup
    .string()
    .oneOf(["user", "admin"], "Onjuiste rol")
    .required("Required"),
  tokenLevel: yup
    .string()
    .oneOf(
      ["development", "production"],
      "Onjuiste toepassing"
    )
    .required("Required"),
  tokenValue: yup.string(),
});

const initialValues = {
  tokenApp: "yourshipping",
  tokenEmail: "",
  tokenSource: "",
  tokenRole: "user",
  tokenLevel: "development",
  tokenValue: "",
};

const AddTokenForm = () => {
  const isMounted = useIsMounted();
  const [show, setShow] = useState(false);
  const [error, setError] = useState(null);
  const [token, setToken] = useState();

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={async (values, actions) => {
        setToken({});

        try {
          await axios
            .post("/api/auth/token", null, {
              withCredentials: true,
            })
            .then(() => {
              axios
                .post(
                  "/api/token/register",
                  {
                    app: values.tokenApp,
                    email: values.tokenEmail,
                    source: values.tokenSource,
                    role: values.tokenRole,
                    level: values.tokenLevel,
                  },
                  {
                    withCredentials: true,
                  }
                )
                .then((result) => {
                  if (isMounted.current) {
                    setToken({
                      tokenValue: result.data.token,
                    });
                    actions.setSubmitting(false);
                    actions.resetForm();
                    setShow(true);
                  }
                })
                .catch((err) => {
                  actions.setSubmitting(false);
                  actions.resetForm();
                  setError(
                    `Token voor ${values.tokenEmail} is al eerder aangemaakt!`
                  );
                });
            });
        } catch (error) {
          actions.setSubmitting(false);
          actions.resetForm();
          setError(
            `Token voor ${values.tokenEmail} kan niet worden aangemaakt!`
          );
        }
      }}
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        touched,
        values,
        errors,
        isSubmitting,
      }) => (
        <div>
          <Form onSubmit={handleSubmit}>
            <h4>Token aanmaken</h4>
            <Form.Group controlId="tokenEmail">
              <Form.Control
                type="email"
                name="tokenEmail"
                placeholder="E-mailadres"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.tokenEmail || ""}
                isInvalid={touched.tokenEmail && errors.tokenEmail}
              />
              <Form.Control.Feedback type="invalid">
                {errors.tokenEmail}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="tokenSource">
              <Form.Control
                type="source"
                name="tokenSource"
                placeholder="Naam"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.tokenSource || ""}
                isInvalid={touched.tokenSource && errors.tokenSource}
              />
              <Form.Control.Feedback type="invalid">
                {errors.tokenSource}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="tokenRole">
              <Form.Control
                as="select"
                htmlSize={1}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.tokenRole || "user"}
              >
                <option value="user">
                  {roles.find((r) => r.key === "user").value}
                </option>
                <option value="admin">
                  {roles.find((r) => r.key === "admin").value}
                </option>
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                {errors.tokenRole}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="tokenLevel">
              <Form.Control
                as="select"
                htmlSize={1}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.tokenLevel || "development"}
              >
                <option value="development">
                  {levels.find((r) => r.key === "development").value}
                </option>
                <option value="production">
                  {levels.find((r) => r.key === "production").value}
                </option>
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                {errors.tokenLevel}
              </Form.Control.Feedback>
            </Form.Group>

            <Button
              variant="success"
              type="submit"
              onClick={() => {
                setShow(false);
                setError(null);
              }}
              disabled={isSubmitting}
            >
              {isSubmitting ? "Even geduld A.U.B..." : "Token aanmaken"}
            </Button>
          </Form>
          <br />
          <Alert
            style={{ fontSize: "16px" }}
            show={show}
            variant="success"
            onClose={() => setShow(false)}
            dismissible
          >
            <p style={{ wordWrap: "break-word" }}>Token: {token?.tokenValue}</p>
          </Alert>
          <Alert
            style={{ fontSize: "16px" }}
            show={error !== null}
            variant="danger"
            onClose={() => setError(null)}
            dismissible
          >
            <p>{error}</p>
          </Alert>
        </div>
      )}
    </Formik>
  );
};

export default AddTokenForm;
