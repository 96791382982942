import React, { useState } from "react";
import { useHistory, Link } from "react-router-dom";
import { Navbar, Container, Row, Form, Button } from "react-bootstrap";
import { ImCross } from "react-icons/im";
import axios from "axios";
import qs from "qs";
import Logo from "../images/logo.png";

const appname = process.env.REACT_APP_NAME;

const Forget = () => {
  let history = useHistory();
  const [isDisabled, setIsDisabled] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsDisabled(true);

    const data = qs.stringify({
      email: e.target.inputEmail.value,
    });

    try {
      await axios.post("/api/auth/forget", data, {
        withCredentials: true,
      });
    } catch (error) {}

    history.push("/");
  };

  return (
    <div className="background-image container-fluid">
      <Navbar>
        <Navbar.Brand>
          <img src={Logo} width="auto" height="80" alt="" />
          <span
            className="align-bottom font-italic"
            style={{
              fontSize: "52px",
              color: "#e56958",
              marginLeft: "20px",
            }}
          >
            {appname}
          </span>
        </Navbar.Brand>
      </Navbar>

      <div
        style={{
          height: "80vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Container>
          <Row>
            <Form
              className="form-forget"
              onSubmit={handleSubmit}
              style={{ textAlign: "center", backgroundColor: "#002f5f0f" }}
            >
              <Link
                to="/"
                style={{
                  position: "relative",
                  top: "-14px",
                  float: "right",
                  right: "-8px",
                }}
              >
                <ImCross size={14} />
              </Link>
              <h2 style={{ marginTop: "10px", marginBottom: "10px" }}>
                Wachtwoord vergeten
              </h2>
              <Form.Text
                className="text-muted"
                style={{ textAlign: "left", marginBottom: "12px" }}
              >
                Stuur je e-mailadres en je ontvangt binnen enkele minuten een
                e-mail met een tijdelijk wachtwoord.
              </Form.Text>
              <Form.Group>
                <Form.Control
                  type="email"
                  id="inputEmail"
                  className="form-control"
                  placeholder="E-mailadres"
                  required
                />
              </Form.Group>
              <Form.Group>
                <Button
                  variant="primary"
                  type="submit"
                  block
                  disabled={isDisabled}
                >
                  Versturen
                </Button>
              </Form.Group>
            </Form>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default Forget;
