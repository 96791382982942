import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { ImUser, ImUserTie, ImBook, ImInfo, ImExit } from "react-icons/im";
import { OverlayTrigger, Tooltip, Button } from "react-bootstrap";

import axios from "axios";

import AccountSettings from "./accountsettings";
import Swagger from "./swagger";
import About from "./about";

import userContext from "../userContext";

const Account = () => {
  let history = useHistory();
  const { user } = useContext(userContext);
  const [activeButton, setActiveButton] = useState("info");

  if (!user) {
    history.push("/");
  }

  const onhandleLogout = async () => {
    try {
      await axios.post("/api/auth/logout", null, {
        withCredentials: true,
      });
    } catch (error) {}

    history.push("/");
  };

  return (
    <div>
      <div className="sidebar">
        <OverlayTrigger
          key={"account"}
          placement={"bottom"}
          overlay={
            <Tooltip id="tooltip-disabled">{`Welkom, ${user.name}`}</Tooltip>
          }
        >
          <Button
            variant={activeButton === "account" ? "light" : "link"}
            onClick={() => setActiveButton("account")}
            style={{
              position: "absolute",
              display: "inline-block",
              top: "1em",
              width: "100%",
            }}
          >
            <span>
              {user.role === "admin" ? (
                <ImUserTie size={24} />
              ) : (
                <ImUser size={24} />
              )}
            </span>
          </Button>
        </OverlayTrigger>

        <OverlayTrigger
          key={"swagger"}
          placement={"bottom"}
          overlay={<Tooltip id="tooltip-disabled">Documentatie</Tooltip>}
        >
          <Button
            variant={activeButton === "swagger" ? "light" : "link"}
            onClick={() => setActiveButton("swagger")}
            style={{
              position: "absolute",
              display: "inline-block",
              top: "4em",
              width: "100%",
            }}
          >
            <span>
              <ImBook size={24} />
            </span>
          </Button>
        </OverlayTrigger>

        <OverlayTrigger
          key="info"
          placement={"bottom"}
          overlay={<Tooltip id="tooltip-disabled">Info</Tooltip>}
        >
          <Button
            variant={activeButton === "info" ? "light" : "link"}
            onClick={() => setActiveButton("info")}
            style={{
              position: "absolute",
              display: "inline-block",
              top: "7em",
              width: "100%",
            }}
          >
            <span>
              <ImInfo size={24} />
            </span>
          </Button>
        </OverlayTrigger>

        <OverlayTrigger
          overlay={<Tooltip id="tooltip-disabled">Uitloggen</Tooltip>}
        >
          <Button
            variant="link"
            onClick={onhandleLogout}
            style={{
              position: "absolute",
              display: "inline-block",
              bottom: "1em",
              width: "100%",
            }}
          >
            <span>
              <ImExit size={24} />
            </span>
          </Button>
        </OverlayTrigger>
      </div>
      <div className="main" style={{ marginLeft: "75px", marginRight: "15px" }}>
        {activeButton === "account" && <AccountSettings />}
        {activeButton === "swagger" && <Swagger />}
        {activeButton === "info" && <About />}
      </div>
    </div>
  );
};

export default Account;
